.about-aboutSection {
  background-color: white;
  width: 40vw;
  font-family: "Chirp-regular";
  font-size: 16px;
  margin: 20px auto;
  border-radius: 5px;
  border: 0.01px solid rgb(200, 200, 200);
}

.about-aboutSectionTitle {
  font-weight: bold;
  font-size: 24px;
  margin: 1%;
}

.about-aboutSectionText {
  margin: 1%;
}

.about-aboutHeader {
  font-weight: bold;
}

.about-aboutSectionBottom {
  border: 0.01px solid rgb(200, 200, 200);
  background-color: white;
  width: 80vw;
  font-family: "Chirp-regular";
  font-size: 16px;
  column-count: 2;
  column-gap: 20px;
  overflow: auto;
  border-radius: 5px;
  margin: 20px auto;
}
