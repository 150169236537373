body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Chirp-bold";
  src: local("Chirp-bold"),
    url(/static/media/chirp-bold-web.ab5400e4.woff) format("truetype");
}

@font-face {
  font-family: "Chirp-regular";
  src: local("Chirp-regular"),
    url(/static/media/chirp-regular-web.270268b0.woff) format("truetype");
}

@font-face {
  font-family: "Chirp-medium";
  src: local("Chirp-medium"),
    url(/static/media/chirp-medium-web.9b05cdf3.woff) format("truetype");
}

@font-face {
  font-family: "Chirp-heavy";
  src: local("Chirp-heavy"),
    url(/static/media/chirp-heavy-web.c959119b.woff) format("truetype");
}

body {
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.logo {
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.homepageContainer {
  height: 100vh;
  width: 100vw;
}

.circles {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.circleText {
  opacity: 0;
  transition: opacity 0.5s;
  font-family: "Chirp-regular";
}

.circleContainer {
  /* set it to relative so the text can be positioned relative to the container */
  display: flex;
  grid-gap: 5vh;
  gap: 5vh;
  flex-direction: column;
  align-items: center; /* This centers the children horizontally */
  justify-content: center; /* This centers the children vertically */
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.circleContainer:hover .circleText {
  opacity: 1;
}

.circleImage {
  border-radius: 50%;
  height: 60vh;
  width: 60vh;
  background-color: white;
}

#homepageLeftCircle:hover {
  -webkit-transform: translateX(-10vw);
          transform: translateX(-10vw);
}

#homepageRightCircle:hover {
  -webkit-transform: translateX(10vw);
          transform: translateX(10vw);
}

.circleImage:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  z-index: 1000;
  border: 1vh solid #404e36;
}

#homepageCenterCircle {
  z-index: 2;
}

#homepageRightCircle {
  margin-left: -20vw;
  z-index: 1;
}

#homepageLeftCircle {
  margin-right: -20vw;
  z-index: 1;
}

@media (max-width: 767px) {
  .circles {
    flex-direction: column;
  }

  .circleImage {
    width: 35vh;
    height: 35vh;
  }

  #homepageRightCircle {
    margin-left: 0;
    margin-right: 0;
    margin-top: -20vh;
  }
  #homepageLeftCircle {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: -20vh;
  }
}

.aboutSection {
  background-color: white;
  width: 90vw;
  margin: 3vh;
  font-family: "Chirp-regular";
  font-size: 16px;
  border-radius: 5px;
  border: 0.01px solid rgb(200, 200, 200);
}

.aboutSectionTitle {
  font-weight: bold;
  font-size: 24px;
  margin: 10px;
}

.aboutSectionText {
  margin: 10px;
}

.surveyContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
}

.Survey {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background: #f3f3f3;
}

.Survey-Page {
  margin: 20px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.Buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  position: fixed;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.Buttons button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.Buttons button[disabled] {
  color: #888;
  cursor: not-allowed;
}

.Buttons button:hover:not([disabled]) {
  color: #007bff;
}

.twitterLogo {
  position: absolute;
  top: 34vh;
  left: 48vw;
  width: 2vh;
  height: 2vh;
  padding: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  background-color: white;
  background-image: url(/static/media/twitter-white-bg.d9f9f616.png);
  background-size: 40px;
  z-index: 2;
}

.twitterLine {
  position: absolute;
  top: 35vh;
  left: 50vw;
  height: 100%;
  border: 2px solid white;
}

.twitterContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
}

.leftTweet,
.rightTweet {
  width: calc(50% - 10vh); /* Assuming the line in the middle takes 10vh */
  display: flex; /* New */
  justify-content: center; /* New */
  position: relative;
  z-index: 2;
}

/* .leftTweet::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: calc(50vw - 5vh); 
  border-top: 3px solid white;
  z-index: 1;
}

.rightTweet::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: calc(50vw - 5vh); 
  border-top: 3px solid white;
  z-index: 1;
} */

.tweetCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wordcloud {
  position: absolute;
  right: 5vw;
  width: 30vw;
  height: 30vh;
  background: white;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.wordcloud:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.title {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.5em;
  font-weight: bold;
}

#titleLeft {
  text-align: right;
}

#titleRight {
  text-align: left;
}

.tweet-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #d1d3d4;
  font-family: "Chirp-medium";
  border-radius: 5px;
  width: 40vw;
  height: auto;
  padding: 15px;
  margin: 20px;
  padding-bottom: 3vh;
}

.tweet-header {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  margin-right: 1vw;
  object-fit: cover;
}

.tweet-user-info {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
}

.username {
  font-family: "Chirp-heavy";
}

.handle {
  color: #657786;
  font-family: "Chirp-regular";
}

.tweet-content {
  margin: 10px 0;
}

.tweet-image img {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #e1e8ed;
  right: 5vw;
  width: 32vw;
  height: auto;
  background: white;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.tweet-body {
  margin-left: 5vw;
  width: 32vw;
  top: 0;
}

.descriptionText {
  margin-top: 5%;
  font-family: "Chirp-medium";
}

.tweet-image:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.tweet-footer {
  color: #657786;
}

@media (max-width: 767px) {
  .twitterLine {
    display: none;
  }

  .leftTweet,
  .rightTweet {
    width: 100vw; /* Assuming the line in the middle takes 10vh */
    margin-bottom: 0vh;
  }

  .leftTweet {
    margin-right: 0vh;
  }

  .rightTweet {
    margin-left: 0vh;
  }

  .tweet-card {
    width: 90vw;
    padding: 15px;
    margin: 20px;
  }

  .tweet-body {
    width: 80vw;
  }

  .tweet-image img {
    width: 80vw;
  }

  .profile-image {
  }
}

html {
  background-color: rgb(250, 246, 240);
}

.author-circle-container {
  transition-property: border-color, -webkit-transform;
  transition-property: transform, border-color;
  transition-property: transform, border-color, -webkit-transform;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0s, 0.3s;
}

.author-circle-container.active {
  -webkit-transform: translateY(-30px) scale(1.3);
          transform: translateY(-30px) scale(1.3);
}

.author-circle {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  border: 1px solid rgb(200, 200, 200);
}

.author-circle-container.active .author-circle {
  border: 3px solid red;
}

.author-list {
  margin: 5vw;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 50px;
  gap: 50px; /* adjust this value to control the space between the circles */
  justify-content: space-around; /* adjust this to align the items as you prefer */
}

.closeModal {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.closeModal {
  font-size: 18px;
  color: #999;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.closeModal:hover {
  color: #333;
}

.modal-content {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.modal-citations {
  font-size: 14px;
  color: #666;
}

.modal-citations p {
  margin: 0;
}

.author-circle:active {
  border: 5px solid brown;
}

.citation {
  margin-top: 2%;
}

.bibContainer {
  padding: 20px;
  padding-bottom: 50px;
  background-color: white;
  border-radius: 5px;
  width: 80vw;
  margin: 20px auto;
}

#intro {
  font-style: italic;
}

hr.final {
  border-top: 3px double;
}

.about-aboutSection {
  background-color: white;
  width: 40vw;
  font-family: "Chirp-regular";
  font-size: 16px;
  margin: 20px auto;
  border-radius: 5px;
  border: 0.01px solid rgb(200, 200, 200);
}

.about-aboutSectionTitle {
  font-weight: bold;
  font-size: 24px;
  margin: 1%;
}

.about-aboutSectionText {
  margin: 1%;
}

.about-aboutHeader {
  font-weight: bold;
}

.about-aboutSectionBottom {
  border: 0.01px solid rgb(200, 200, 200);
  background-color: white;
  width: 80vw;
  font-family: "Chirp-regular";
  font-size: 16px;
  -webkit-column-count: 2;
          column-count: 2;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  overflow: auto;
  border-radius: 5px;
  margin: 20px auto;
}

