.citation {
  margin-top: 2%;
}

.bibContainer {
  padding: 20px;
  padding-bottom: 50px;
  background-color: white;
  border-radius: 5px;
  width: 80vw;
  margin: 20px auto;
}

#intro {
  font-style: italic;
}

hr.final {
  border-top: 3px double;
}
