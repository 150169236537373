.twitterLogo {
  position: absolute;
  top: 34vh;
  left: 48vw;
  width: 2vh;
  height: 2vh;
  padding: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  background-color: white;
  background-image: url(../../images/twitter-white-bg.png);
  background-size: 40px;
  z-index: 2;
}

.twitterLine {
  position: absolute;
  top: 35vh;
  left: 50vw;
  height: 100%;
  border: 2px solid white;
}

.twitterContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
}

.leftTweet,
.rightTweet {
  width: calc(50% - 10vh); /* Assuming the line in the middle takes 10vh */
  display: flex; /* New */
  justify-content: center; /* New */
  position: relative;
  z-index: 2;
}

/* .leftTweet::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: calc(50vw - 5vh); 
  border-top: 3px solid white;
  z-index: 1;
}

.rightTweet::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: calc(50vw - 5vh); 
  border-top: 3px solid white;
  z-index: 1;
} */

.tweetCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wordcloud {
  position: absolute;
  right: 5vw;
  width: 30vw;
  height: 30vh;
  background: white;
  transition: transform 0.2s;
}

.wordcloud:hover {
  transform: scale(1.1);
}

.title {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.5em;
  font-weight: bold;
}

#titleLeft {
  text-align: right;
}

#titleRight {
  text-align: left;
}

.tweet-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #d1d3d4;
  font-family: "Chirp-medium";
  border-radius: 5px;
  width: 40vw;
  height: auto;
  padding: 15px;
  margin: 20px;
  padding-bottom: 3vh;
}

.tweet-header {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  margin-right: 1vw;
  object-fit: cover;
}

.tweet-user-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.username {
  font-family: "Chirp-heavy";
}

.handle {
  color: #657786;
  font-family: "Chirp-regular";
}

.tweet-content {
  margin: 10px 0;
}

.tweet-image img {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #e1e8ed;
  right: 5vw;
  width: 32vw;
  height: auto;
  background: white;
  transition: transform 0.2s;
}

.tweet-body {
  margin-left: 5vw;
  width: 32vw;
  top: 0;
}

.descriptionText {
  margin-top: 5%;
  font-family: "Chirp-medium";
}

.tweet-image:hover {
  transform: scale(1.2);
}

.tweet-footer {
  color: #657786;
}

@media (max-width: 767px) {
  .twitterLine {
    display: none;
  }

  .leftTweet,
  .rightTweet {
    width: 100vw; /* Assuming the line in the middle takes 10vh */
    margin-bottom: 0vh;
  }

  .leftTweet {
    margin-right: 0vh;
  }

  .rightTweet {
    margin-left: 0vh;
  }

  .tweet-card {
    width: 90vw;
    padding: 15px;
    margin: 20px;
  }

  .tweet-body {
    width: 80vw;
  }

  .tweet-image img {
    width: 80vw;
  }

  .profile-image {
  }
}
