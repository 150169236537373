.surveyContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
}

.Survey {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background: #f3f3f3;
}

.Survey-Page {
  margin: 20px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.Buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.Buttons button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.Buttons button[disabled] {
  color: #888;
  cursor: not-allowed;
}

.Buttons button:hover:not([disabled]) {
  color: #007bff;
}
