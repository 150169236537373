.aboutSection {
  background-color: white;
  width: 90vw;
  margin: 3vh;
  font-family: "Chirp-regular";
  font-size: 16px;
  border-radius: 5px;
  border: 0.01px solid rgb(200, 200, 200);
}

.aboutSectionTitle {
  font-weight: bold;
  font-size: 24px;
  margin: 10px;
}

.aboutSectionText {
  margin: 10px;
}
