html {
  background-color: rgb(250, 246, 240);
}

.author-circle-container {
  transition-property: transform, border-color;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0s, 0.3s;
}

.author-circle-container.active {
  transform: translateY(-30px) scale(1.3);
}

.author-circle {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  border: 1px solid rgb(200, 200, 200);
}

.author-circle-container.active .author-circle {
  border: 3px solid red;
}

.author-list {
  margin: 5vw;
  display: flex;
  flex-wrap: wrap;
  gap: 50px; /* adjust this value to control the space between the circles */
  justify-content: space-around; /* adjust this to align the items as you prefer */
}

.closeModal {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.closeModal {
  font-size: 18px;
  color: #999;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.closeModal:hover {
  color: #333;
}

.modal-content {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.modal-citations {
  font-size: 14px;
  color: #666;
}

.modal-citations p {
  margin: 0;
}

.author-circle:active {
  border: 5px solid brown;
}
